<template>
  <div>
    <v-container class="mb-12">
      <!-- Messages navigation bar -->
      <div class="nav-outer-wrapper" id="sticky-nav">
        <!-- <div class="grey lighten-4 px-2 py-3 d-flex align-center"> -->
        <v-row class="grey lighten-5 align-center">
          <v-col class="text-center" cols="2" sm="3">
            <v-btn @click="toDashboard" text>
              <v-icon>mdi-arrow-left</v-icon>
              <!-- <span class="d-none d-sm-flex custom-transform-class text-none">Back</span> -->
            </v-btn>
          </v-col>
          <v-col cols="8" sm="6">
            <div class="mx-auto text-center">
              <p class="mb-0 mt-3 font-weight-light headline">My emails</p>
              <span class="grey--text font-weight-light"
                >{{ selectedDataSetFilter.length }}
              </span>
              <span
                class="grey--text font-weight-light"
                v-if="selectedDataSetFilter.length > 1"
                >messages</span
              >
              <span class="grey--text font-weight-light" v-else>message</span>
              <span
                v-if="inboxReadUnread == 0"
                class="grey--text font-weight-light"
              >
                ( {{ $store.state.staffEmails.staff.data.Unread }} unread )
              </span>
            </div>
          </v-col>
          <v-col class="text-center d-flex" cols="2" sm="3">
            <v-btn
              class="d-sm-none"
              @click="sendEmailDialog = true"
              fab
              small
              color="success"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              class="d-none d-sm-block"
              @click="sendEmailDialog = true"
              rounded
              color="secondary"
            >
              <v-icon color="white" left>mdi-plus</v-icon>
              <span class="custom-transform-class text-none white--text"
                >Compose</span
              >
            </v-btn>
            <v-btn @click="refreshEmails" text color="success">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- </div> -->

        <div class="white d-flex align-center text-center py-0 px-3">
          <v-row>
            <v-col cols="1" class="d-flex align-center">
              <transition name="fade" mode="out-in">
                <v-menu v-if="checkedMessagesForAction.length" offset-y>
                  <template v-slot:activator="{ on }">
                    <div class="pointer settings text-center" v-on="on">
                      <v-icon
                        class="mx-0 ml-sm-1 ml-md-3 ml-lg-5 ml-xl-11 px-0"
                        color="primary"
                        >mdi-cog</v-icon
                      >
                    </div>
                  </template>
                  <v-list>
                    <v-list-item @click="toggleReadUnreadMultiple">
                      <v-list-item-title v-if="inboxReadUnread === 2"
                        >Mark as read</v-list-item-title
                      >
                      <v-list-item-title v-else
                        >Mark as unread</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="markAsComplete">
                      <v-list-item-title>Mark as complete</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="archiveSelectedMessages">
                      <v-list-item-title
                        >Archive selected messages</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </transition>
            </v-col>
            <v-col
              class="
                my-3
                d-flex
                align-center
                justify-space-between justify-sm-center
              "
              cols="10"
            >
              <div
                style="min-width: 50px"
                class="pointer mt-2 mx-1 mr-sm-5 unread text-center"
                @click="inboxReadUnread = 0"
              >
                <v-icon :color="checkReadStatus(0)"
                  >mdi-mailbox-up-outline</v-icon
                >
                <p
                  class="mb-0 caption text-center"
                  :class="checkReadStatusText(0)"
                >
                  Inbox
                </p>
              </div>
              <div
                style="min-width: 50px"
                class="pointer mt-2 mx-1 mr-sm-5 read text-center"
                @click="inboxReadUnread = 1"
              >
                <v-icon :color="checkReadStatus(1)"
                  >mdi-email-open-outline</v-icon
                >
                <p
                  class="mb-0 caption text-center"
                  :class="checkReadStatusText(1)"
                >
                  Read
                </p>
              </div>
              <div
                style="min-width: 50px"
                class="pointer mt-2 mx-1 mr-sm-5 unread text-center"
                @click="inboxReadUnread = 2"
              >
                <v-icon :color="checkReadStatus(2)">mdi-email-outline</v-icon>
                <p
                  class="mb-0 caption text-center"
                  :class="checkReadStatusText(2)"
                >
                  Unread
                </p>
              </div>
              <div
                style="min-width: 50px"
                class="pointer mt-2 mx-1 mr-sm-5 unread text-center"
                @click="inboxReadUnread = 3"
              >
                <v-icon :color="checkReadStatus(3)"
                  >mdi-email-check-outline</v-icon
                >
                <p
                  class="mb-0 caption text-center"
                  :class="checkReadStatusText(3)"
                >
                  Complete
                </p>
              </div>
              <div
                style="min-width: 50px"
                class="pointer mt-2 unread text-center"
                @click="inboxReadUnread = 4"
              >
                <v-icon :color="checkReadStatus(4)"
                  >mdi-email-send-outline</v-icon
                >
                <p
                  class="mb-0 caption text-center"
                  :class="checkReadStatusText(4)"
                >
                  Sent
                </p>
              </div>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
        </div>
      </div>
      <!-- /navigation -->

      <!-- Messages list -->
      <v-card
        :class="{ 'grey lighten-4': item.IsRead }"
        flat
        outlined
        tile
        @click="openMessage(item)"
        class="grey--text text--darken-2 mx-0 mb-1 px-1"
        v-for="item in selectedDataSetFilter"
        :key="item.OID"
      >
        <v-row>
          <v-col
            @click.stop=""
            cols="1"
            class="py-0 d-flex align-center justify-center"
          >
            <v-checkbox
              v-model="checkedMessagesForAction"
              class="ml-3"
              :value="item.OID"
              :key="item.OID"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="11"
            sm="5"
            class="py-0 d-block d-sm-flex align-center justify-start"
          >
            <v-card-title class="d-sm-flex flex-column align-start">
              <span
                class="subtitle-2"
                :class="{
                  'font-weight-black': !item.IsRead,
                  'font-weight-light': item.IsRead,
                }"
                >{{ item.Sender.AccountName }}</span
              >
              <span
                :class="{
                  'font-weight-bold': !item.IsRead,
                  'font-weight-light': item.IsRead,
                }"
                v-if="!item.Sender.AccountName"
                class="subtitle-2"
                >{{ item.Sender.EmailAddress }}</span
              >
              <span class="d-none d-sm-block text-caption">{{
                item.CreatedDate | filterDate
              }}</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-subtitle class="d-block d-sm-none">
              <div>
                <span
                  class="mr-5"
                  :class="{
                    'font-weight-medium': !item.IsRead,
                    'font-weight-light': item.IsRead,
                  }"
                  >{{ item.Subject }}</span
                >
                <v-icon color="grey lighten-1" v-if="item.Attachments.length"
                  >mdi-attachment</v-icon
                >
              </div>
              <div>
                <span class="text-caption">{{
                  item.CreatedDate | filterDate
                }}</span>
              </div>
            </v-card-subtitle>
          </v-col>
          <!-- only visible on sm and up!!! -->
          <v-col cols="12" sm="4" class="py-0 d-none d-sm-flex align-center">
            <v-card-subtitle class="">
              <div class="d-flex justify-space-between align-end">
                <div>
                  <span class="mr-5">{{ item.Subject }}</span>
                  <v-icon
                    small
                    color="grey lighten-1"
                    v-if="item.Attachments.length"
                    >mdi-attachment</v-icon
                  >
                </div>
              </div>
            </v-card-subtitle>
          </v-col>
          <!-- only visible on sm and up!!! -->
          <v-col
            cols="12"
            sm="2"
            class="py-0 d-none d-md-flex align-center justify-center"
          >
            <v-btn
              small
              outlined
              class="mb-n1 mt-n3"
              @click.stop="toggleIsRead(item.OID)"
              v-if="item.IsRead"
            >
              <span class="caption custom-transform-class text-none"
                >Mark as unread</span
              >
              <v-icon small color="grey darken-2" right
                >mdi-email-mark-as-unread</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <!-- Selected email message popup dialog -->
      <v-dialog
        v-model="messageDialog"
        persistent
        fullscreen
        transition="dialog-transition"
      >
        <SelectedEmailMessage
          v-if="selectedMessage"
          :selectedMessage="selectedMessage"
          @closeMsgDialog="closeMsgDialog"
          @replyToMsgDialog="replyToMsgDialog = true"
          @toggleAttachmentSheet="attachmentSheet = !attachmentSheet"
        />
      </v-dialog>

      <!-- Attachment bottom sheet -->
      <v-bottom-sheet v-model="attachmentSheet">
        <AttachmentsBottomSheet
          v-if="selectedMessage"
          :attachmentSheet="attachmentSheet"
          :selectedMessage="selectedMessage"
          @toggleAttachmentSheet="attachmentSheet = !attachmentSheet"
        />
      </v-bottom-sheet>

      <v-overlay :value="loading">
        <v-progress-circular
          color="#E66544"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

      <!-- Email reply popup -->
      <EmailReply
        :replyToMsg="replyToMsgDialog"
        @closeDialog="closeEmailReply"
        @confirmReply="confirmReply"
        @replyError="replyError"
        :messageInfo="selectedMessage ? selectedMessage : {}"
      />

      <SendEmail
        :sendEmailDialog="sendEmailDialog"
        @closeSendEmailDialog="closeSendEmailDialog"
        @confirmEmailSend="confirmEmailSend"
        @emailSendError="emailSendError"
      />

      <!-- Remply confirmation -->
      <Snackbar
        :snackbar="showSnackbar"
        :snackbarText="snackbarText"
        :color="snackBarColor"
        @emitSnackbarTimeout="closeSnackbar"
      />
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmailReply from "@/components/MessageComponents/EmailReply.vue";
import SendEmail from "@/components/MessageComponents/Slots/SendEmail.vue";
import Snackbar from "@/components//Snackbars/Snackbar.vue";
import SelectedEmailMessage from "@/components/MessageComponents/Slots/SelectedEmailMessage.vue";
import AttachmentsBottomSheet from "@/components/MessageComponents/Slots/AttachmentsBottomSheet.vue";
import moment from "moment/src/moment";

export default {
  name: "staff-emails",
  components: {
    EmailReply,
    SendEmail,
    Snackbar,
    SelectedEmailMessage,
    AttachmentsBottomSheet,
  },
  data() {
    return {
      loading: false,
      inboxReadUnread: 0,
      selectedMessage: "",
      messageDialog: false,
      replyToMsgDialog: false,
      sendEmailDialog: false,
      checkedMessagesForAction: [], //checkbox for selecting multiple messages
      attachmentSheet: false,
      showSnackbar: false,
      snackbarText: "",
      snackBarColor: "green",
    };
  },
  methods: {
    refreshEmails() {
      this.$store.dispatch("getAllStaffEmails");
    },
    toDashboard() {
      this.$router.go(-1);
    },
    checkReadStatus(index) {
      //setting the icon color for the mobile message navbar
      if (index === this.inboxReadUnread) {
        return "primary";
      }
      return "grey";
    },
    checkReadStatusText(index) {
      if (index === this.inboxReadUnread) {
        return "orange--text text--darken-4";
      }
      return "grey--text";
    },
    openMessage(message) {
      // console.log(message)
      this.selectedMessage = message;
      this.messageDialog = true;
      if (!message.IsRead) {
        // when msg is opened we toggle its Read/unRead status
        this.$store
          .dispatch("Messages/toggleMessageIsRead", message.OID)
          .then(() => {
            this.$store.dispatch("getAllStaffEmails");
            this.$store.dispatch("Messages/getSentMessages");
          });
      }
    },
    toggleIsRead(id) {
      this.loading = true;
      let idArray = [];
      idArray.push(id);
      // console.log(idArray)
      this.$store.dispatch("Messages/toggleMessageIsRead", idArray).then(() => {
        this.$store
          .dispatch("getAllStaffEmails")
          .then(() => (this.loading = false));
        this.$store.dispatch("Messages/getSentMessages");
      });
    },
    toggleReadUnreadMultiple() {
      this.loading = true;
      this.$store
        .dispatch("Messages/toggleMessageIsRead", this.checkedMessagesForAction)
        .then(() => {
          this.$store.dispatch("getAllStaffEmails").then(() => {
            this.loading = false;
            this.checkedMessagesForAction = [];
          });
          this.$store.dispatch("Messages/getSentMessages");
        });
    },
    archiveSelectedMessages() {
      this.loading = true;
      this.$store
        .dispatch(
          "Messages/archiveMultipleMessages",
          this.checkedMessagesForAction
        )
        .then(() => {
          console.log("msg ARCHIVED!");
          this.$store.dispatch("getAllStaffEmails").then(() => {
            this.loading = false;
            this.checkedMessagesForAction = [];
          });
        });
    },
    markAsComplete() {
      this.loading = true;
      this.$store
        .dispatch(
          "Messages/completeMultipleMessages",
          this.checkedMessagesForAction
        )
        .then(() => {
          console.log("msg COMPLETED!");
          this.$store.dispatch("getAllStaffEmails").then(() => {
            this.loading = false;
            this.checkedMessagesForAction = [];
          });
        });
    },
    closeEmailReply() {
      this.replyToMsgDialog = false; //close reply msg dialog
      this.messageDialog = false; //close original msg dialog
      this.selectedMessage = ""; //empty selected messasge so that msgReply component can also reset watcher
    },
    confirmReply() {
      this.showSnackbar = true;
      this.snackbarText = "Reply sent";
      this.$store.dispatch("getAllStaffEmails");
      this.$store.dispatch("Messages/getSentMessages");
    },
    replyError() {
      this.showSnackbar = true;
      this.snackbarText = "Error. Reply NOT sent";
      this.snackBarColor = "red";
    },
    confirmEmailSend() {
      this.showSnackbar = true;
      this.snackbarText = "Email sent";
    },
    emailSendError() {
      this.showSnackbar = true;
      this.snackBarColor = "red";
      this.snackbarText = "Error. Email could not be sent.";
    },
    closeSnackbar() {
      this.showSnackbar = false;
      this.snackBarColor = "green";
    },
    closeMsgDialog() {
      this.messageDialog = false;
      this.selectedMessage = "";
    },
    closeSendEmailDialog() {
      this.sendEmailDialog = false;
    },
  },
  computed: {
    ...mapState({
      emails: (state) => state.staffEmails.staff.data, //full email data set e.g Count, Data etc
      completed: (state) => state.staffEmails.completed.data,
      sent: (state) => state.sentMessages,
    }),
    selectedDataSetFilter() {
      //filtering between inbox, read, unRead and passing it to the message board
      if (this.inboxReadUnread === 1) {
        return this.emails.Data.filter((item) => {
          return item.IsRead;
        });
      }
      if (this.inboxReadUnread === 2) {
        return this.emails.Data.filter((item) => {
          return !item.IsRead;
        });
      }
      if (this.inboxReadUnread === 3) {
        return this.completed.Data;
      }
      if (this.inboxReadUnread === 4) {
        return this.sent.Data;
      }
      return this.emails.Data;
    },
  },
  filters: {
    filterDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style>
</style>