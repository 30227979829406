<template>
  <StaffEmails />
</template>

<script>
import StaffEmails from '@/components/MessageComponents/StaffEmails.vue';

export default {
  name: 'staff-emails-view',
  components: {
    StaffEmails
  }
  ,
  created(){
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user === ''){
      this.$router.push('/');
    }
  }
}
</script>

<style>

</style>